<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";
import { isObject } from "lodash";

export default {
  components: {
    MasterDetail
  },
  data() {
    return {
      opts: {
        atividades: [],
        colaboradores: [],
        projetos: [],
        statuses: [
          {
            id: 0,
            nome:
              '<i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light" style="color: #135153"></i>'
          },
          {
            id: 1,
            nome:
              '<i aria-hidden="true" class="v-icon notranslate mdi mdi-alert-circle theme--light" style="color: #EC4C47"></i>'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.HTML,
          hideInform: true,
          rel: { to: "statuses", key: "id", name: "nome" }
        },
        {
          key: "funcionario_id",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          hideInTable: true,
          colSize: 6,
          rel: { to: "colaboradores", key: "id", name: "label" }
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          hideInTable: true,
          colSize: 6
        },
        {
          key: "projeto",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "nome" },
          hideInTable: true
        },
        {
          key: "data",
          name: "Data",
          type: this.$fieldTypes.DATE,
          hideInTable: true,
          colSize: 4
        },
        {
          key: "tempo",
          name: "Tempo",
          type: this.$fieldTypes.TIME,
          hideInTable: true,
          colSize: 4
        },
        {
          key: "percentual",
          name: "Percentual",
          type: this.$fieldTypes.DOUBLE,
          hideInTable: true,
          colSize: 4
        },
        {
          key: "atividade",
          name: "Atividade",
          hideInTable: true,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "atividades", key: "nome", name: "nome" }
        },
        {
          key: "referencia",
          name: "Referência atividade",
          type: this.$fieldTypes.TEXT,
          hideInTable: true
        },
        {
          key: "projeto",
          name: "Projeto",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
          nowrap: true
        },
        {
          key: "atividade",
          name: "Atividade",
          hideInform: true,
          type: this.$fieldTypes.TEXT
        },
        {
          key: "email",
          name: "E-mail",
          hideInform: true,
          type: this.$fieldTypes.TEXT
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          hideInform: true
        },
        {
          key: "horas_uteis",
          name: "Horas úteis",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "ausencias",
          name: "Ausências",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "horas_realizadas",
          name: "Horas realizadas",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "horas_previstas",
          name: "Horas previstas",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "horas_pendentes",
          name: "Horas pendentes",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "budget",
          name: "Budget %",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "real",
          name: "Real %",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "delta",
          name: "Delta ativ.",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        },
        {
          key: "ref",
          name: "Ref. ativ.",
          hideInform: true,
          type: this.$fieldTypes.DOUBLE,
          align: 1
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/nivel-hierarquicos`;
    },
    customResource() {
      const notify = msg =>
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Mockado",
          text: msg
        });
      return {
        async get(...args) {
          console.log("Timesheet customResource GET", ...args);
          notify("GET mockado");
          return [
            {
              id: 1,
              status: 0,
              projeto: "Auditoria de projetos (Logs)",
              atividade: "Teste",
              email: "jose.moraes@xpi.com.br",
              competencia: "2025-01",
              horas_uteis: 176,
              ausencias: -120,
              horas_realizadas: 56,
              horas_previstas: 56,
              horas_pendentes: 0,
              budget: 0,
              real: 0,
              delta: 0,
              ref: 0
            },
            {
              id: 2,
              status: 0,
              projeto: "Auditoria de projetos (Logs)",
              atividade: "Teste",
              email: "jose.moraes@xpi.com.br",
              competencia: "2025-02",
              horas_uteis: 176,
              ausencias: -120,
              horas_realizadas: 56,
              horas_previstas: 56,
              horas_pendentes: 0,
              budget: 0,
              real: 0,
              delta: 0,
              ref: 0
            },
            {
              id: 3,
              status: 1,
              projeto: "Auditoria de projetos (Logs)",
              atividade: "Teste",
              email: "luiz.souza@xpi.com.br",
              competencia: "2025-01",
              horas_uteis: 176,
              ausencias: 0,
              horas_realizadas: 0,
              horas_previstas: 35.2,
              horas_pendentes: 35.2,
              budget: 0,
              real: 0,
              delta: 0,
              ref: 0
            },
            {
              id: 4,
              status: 0,
              projeto: "Auditoria de projetos (Logs)",
              atividade: "Teste",
              email: "luiz.souza@xpi.com.br",
              competencia: "2025-02",
              horas_uteis: 176,
              ausencias: -120,
              horas_realizadas: 56,
              horas_previstas: 56,
              horas_pendentes: 0,
              budget: 0,
              real: 0,
              delta: 0,
              ref: 0
            }
          ];
        },
        async save(...args) {
          console.log(
            "Timesheet customResource SAVE",
            isObject(args) ? { ...args } : args
          );
          notify("SAVE não implementado");
          return {};
        },
        async patch(...args) {
          console.log(
            "Timesheet customResource PATCH",
            isObject(args) ? { ...args } : args
          );
          notify("PATCH não implementado");
          return {};
        },
        async delete(...args) {
          console.log(
            "Timesheet customResource DELETE",
            isObject(args) ? { ...args } : args
          );
          notify("DELETE não implementado");
          return {};
        }
      };
    }
  },
  methods: {
    confirmDeleteMessage({ email, projeto }) {
      return `Você está removendo as horas lançadas para o colaborador ${email} no projeto ${projeto}, deseja continuar?`;
    },
    doImport() {
      window.alert("TO DO Implementar");
    },
    async getAtividades() {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "alert",
        title: "Mockado",
        text: "GET de lista de atividades mockado."
      });
      this.opts.atividades = [
        { nome: "Reuniões com clientes" },
        { nome: "Planejamento e estatégia" },
        { nome: "Treinamentos e capacitação" },
        { nome: "Pesquisa e desenvolvimento" },
      ];
      // try {
      //   const { get } = this.apiResource(
      //     `/v1/ativacaoped/${this.clientId}/responsaveis/selecao`
      //   );
      //   const response = await get();

      //   if (Array.isArray(response)) {
      //     this.opts.colaboradores = response.map((responsavel) => ({ ...responsavel, label: `${responsavel.nome} (${responsavel.email})` }));
      //     return;
      //   }

      //   this.opts.colaboradores = [];
      // } catch (error) {
      //   this.notify(error);
      //   this.opts.colaboradores = [];
      // }
    },
    async getBusinessPlans() {
      try {
        this.opts.projetos = [];
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-plans/selecao`
        );
        this.opts.projetos = await get();
      } catch (error) {
        this.opts.projetos = [];
      }
    },
    async getColaboradores() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/responsaveis/selecao`
        );
        const response = await get();

        if (Array.isArray(response)) {
          this.opts.colaboradores = response.map((responsavel) => ({ ...responsavel, label: `${responsavel.nome} (${responsavel.email})` }));
          return;
        }

        this.opts.colaboradores = [];
      } catch (error) {
        this.notify(error);
        this.opts.colaboradores = [];
      }
    },
    async onOpenFormDialog() {
      const promises = [];
      const { atividades, colaboradores, projetos } = this.opts;

      if (!atividades.length) {
        promises.push(this.getAtividades());
      }

      if (!colaboradores.length) {
        promises.push(this.getColaboradores());
      }

      if (!projetos.length) {
        promises.push(this.getBusinessPlans());
      }

      await Promise.all(promises);
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :cols="cols"
    :confirmDeleteMessage="confirmDeleteMessage"
    :customResource="customResource"
    formTitle="Alocar horas"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :opts="opts"
    @onOpenFormDialog="onOpenFormDialog"
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div>
  </MasterDetail>
</template>
